import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Applied Mathematics`}</h1>
    <h2>{`Applied Mathematics 1 Books:`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1h3XzndZ4o7unlhFoGD3lJqPnI_yVi-Lj"
      }}>{`Download: Brief applied calculus by James Stewart and Daniel Clegg `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PN0R1dVZyQmgtVFE"
      }}>{`Download: Engineering mathematics by John Bird 5th ed. `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-POGhJY0xSRHR3Ym8"
      }}>{`Download: Mathematical formula handbook`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1AwGxceWddF7pd3V0HSEb_NvLLYxOYZlY"
      }}>{`Download: Thomas Calculus 11th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=12ix57piXKF5fszexFanVMZ8FOqwUKurJ"
      }}>{`Download: Solution Manual Thomas Calculus 11th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PNzdlV3daWDNTOGs"
      }}>{`Download: Thomas Calculus 12th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PdjlZRWYtZVNMZWc"
      }}>{`Download: Solution Manual Thomas Calculus 12th Ed.`}</a></p>
    <h2>{`Applied Mathematics 2 Books:`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1XRn8EG2TJikouWLycuRQxvSoABqOIDhk"
      }}>{`Download: A first course in differential equations with modeling applications by Dennis G. Zill 9th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PWEpxbktJSWZ0MmM"
      }}>{`Download: Solution Manual A first course in differential equations with modeling applications by Dennis G. Zill `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PUTNkS28xdmVoZjA"
      }}>{`Download: Solution Manual A first course in differential equations with modeling applications by Dennis G. Zill 7th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PM1FXQW8yZ0VLU3M"
      }}>{`Download: Advanced engineering mathematics by Erwin Kreyszig 10th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PLUtEbTk3MUZhUVE"
      }}>{`Download: Solution Manual Advanced engineering mathematics by Erwin Kreyszig 9th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PN2lCMjgtZGYxYmM"
      }}>{`Download: Solution Manual Advanced engineering mathematics by Erwin Kreyszig 8th ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PdzlXZmxMX1g0N2c"
      }}>{`Download: Elementary differential equations and boundary value problems by William E. Boyce and Richard C. Diprima 9th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PRy1lV2M3elF6YUU"
      }}>{`Download: Introduction to differential equations by Jeffrey R. Chasnov `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      